export const links = [
  { path: "/vglista", text: "Forside" },
  { path: "/vglista/feat-you", text: "Feat-you" },
  { path: "/vglista/artister", text: "Artister" },
  { path: "https://www.vglista.no/topplister", text: "Topplistene" },
  {
    path: "/vglista/informasjon/golden-circle",
    text: "Golden Circle",
  },
  {
    path: "/vglista/informasjon/ny-paa-konsert",
    text: "Ny på konsert",
  },
  {
    path: "/tag/vg-lista/",
    text: "Artikler og videoklipp",
  },
];
